import { Wrap } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const PartnerLogos = () => {
  const images = [
    "ibpi-logo (2).png",
    "KingsCollege.png",
    "LSCFT_LOGO.jpg",
    "LU - Logo - Positive (CMYK).jpg",
    "ManchesterFeatureBoxOptimised.png",
    "nihrlogo.jpg",
    "nottinghamlogo.png",
    "QueensLogo.jpg",
    "spectrum logo.png",
    "YorkLogo.jpg",
  ];

  const imageSize = 75;

  return (
    <Wrap gap={3} mt={10} bg="white" p={2} rounded="md">
      <StaticImage
        src={"../images/partners/LU - Logo - Positive (CMYK).jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/LSCFT_LOGO.jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/YorkLogo.jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/KingsCollege.png"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />

      <StaticImage
        src={"../images/partners/FeatureBox-UniversityofOxford.jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/nottinghamlogo.png"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/ManchesterFeatureBoxOptimised.png"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/QueensLogo.jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/nihrlogo.jpg"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/Bipola-01r-2.png"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
      <StaticImage
        src={"../images/partners/spectrum logo.png"}
        alt="Partner Logo"
        height={imageSize}
        placeholder="blurred"
      />
    </Wrap>
  );
};

export default PartnerLogos;
