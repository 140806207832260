import { HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import PackageInfo from "../../package.json";

const VersionInfo = () => {
  return (
    <HStack marginTop={5}>
      <StaticImage
        src={"../images/ISS-lancaster-university.png"}
        alt="Partner Logo"
        height={50}
        placeholder="blurred"
      />
      <Text marginLeft={5} fontSize="lg" color="gray.500">
        Site Version: {PackageInfo.version}
      </Text>
    </HStack>
  );
};

export default VersionInfo;
