import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { useRehydratedForm, useHydrateForm } from "../data/formsApi";
import { useLocation, globalHistory, useNavigate } from "@reach/router";
import { FormContext } from "../context/form-context";

type Props = {
  id: string;
  initialValues: any;
  date?: string;
};

export const usePreservedFormik = ({ id, initialValues, date }: Props) => {
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [disabled, setDisabled] = useState(false);
  const { data, fetchData } = useRehydratedForm(id, disabled);
  const { setDirty } = useContext(FormContext);
  const hydrateForm = useHydrateForm(id);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setDisabled(true);
      if (date) {
        setInitialFormValues(data[parseInt(date)]?.form?.response ?? {});
      } else {
        setInitialFormValues(data[0]?.form?.response ?? {});
      }
    }
  }, [data]);

  const saveValues = useCallback(
    async (values: any) => {
      // TODO: save the values somehow by an ID
      await hydrateForm.mutateAsync(values);
    },
    [id]
  );

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: saveValues,
  });

  useEffect(() => {
    setDirty(formik.dirty);
  }, [formik.dirty]);

  return formik;
};
