import { Button } from "@chakra-ui/react";
import React from "react";
import useSimpleForm from "../../hooks/useSimpleForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleNineReflection = ({ date }: Props) => {
  const { components, formik } = useSimpleForm({
    id: "module-9-reflection",
    fields: [
      {
        id: "question1",
        label: "What helps me to be a consistent parent?",
        type: "textarea",
        value: "",
      },
      {
        id: "question2",
        label: "What helps me to manage my moods?",
        type: "textarea",
        value: "",
      },
      {
        id: "question3",
        label: "What helps me have realistic expectations from my parenting?",
        type: "textarea",
        value: "",
      },
      {
        id: "question4",
        label: "What helps me manage my and/or my child's anxiety?",
        type: "textarea",
        value: "",
      },
      {
        id: "question5",
        label: "What helps me manage my or my child's sleep difficulties?",
        type: "textarea",
        value: "",
      },
      {
        id: "question6",
        label:
          "Which people can help me when I can to share some responsibility for day to day tasks (people or organisations or both)?",
        type: "textarea",
        value: "",
      },
      {
        id: "question7",
        label:
          "What do I need to do to have recreational time so I can recharge my batteries?",
        type: "textarea",
        value: "",
      },
    ],
    date,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {components}
      {!date || date === "0" ? (
        <SaveButton isLoading={formik.isSubmitting} />
      ) : null}
    </form>
  );
};

export default ModuleNineReflection;
