import { Alert } from "@chakra-ui/react";
import React from "react";

type Props = {
  formik: any;
};

const SaveAlert = ({ formik }: Props) => {
  return formik.dirty ? (
    <Alert my={2} status="warning">
      Remember to save changes when done!
    </Alert>
  ) : null;
};

export default SaveAlert;
