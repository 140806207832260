import { Button, ButtonProps, toast, useToast } from "@chakra-ui/react";
import React from "react";

type Props = {
  onClickExtra?: () => void;
} & ButtonProps;

const SaveButton = ({ onClickExtra, ...btnProps }: Props) => {
  const toast = useToast();

  return (
    <Button
      type="submit"
      colorScheme="green"
      {...btnProps}
      onClick={() => {
        onClickExtra?.();
        toast({
          title: "Saved",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }}
    >
      Save Changes
    </Button>
  );
};

export default SaveButton;
