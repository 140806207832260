import { Button } from "@chakra-ui/react";
import React from "react";
import useSimpleForm from "../../hooks/useSimpleForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleFiveReflectionOne = ({ date }: Props) => {
  const { components, formik } = useSimpleForm({
    id: "module-5-reflection-1",
    fields: [
      {
        id: "question1",
        label: "Do you have high expectations for yourself and your children?",
        type: "boolean",
        value: null,
      },
      {
        id: "subquestion11",
        label:
          "How does having high expectations for your children help with your parenting?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "subquestion12",
        label:
          "Does having high expectations present any challenges for your parenting?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "subquestion13",
        label:
          "How might you maximise the benefits and reduce the parenting challenges linked to high expectations?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "question2",
        label:
          "Do you ever make impulsive decisions for yourself or your children?",
        type: "boolean",
        value: null,
      },
      {
        id: "subquestion21",
        label:
          "How does making impulsive decisions for your children help with your parenting?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
      {
        id: "subquestion22",
        label:
          "Does making impulsive decisions present any challenges for your parenting?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
      {
        id: "subquestion23",
        label:
          "How might you maximise the benefits and reduce the parenting challenges linked to making impulsive decisions?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
    ],
    date,
  });

  return (
    <form
      style={{ marginBottom: "1em" }}
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {components}
      {!date || date === "0" ? (
        <SaveButton isLoading={formik.isSubmitting} />
      ) : null}
    </form>
  );
};

export default ModuleFiveReflectionOne;
