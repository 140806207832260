import {
  Alert,
  AlertIcon,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import SaveAlert from "../components/SaveAlert";
import { usePreservedFormik } from "./usePreservedFormik";

type Props = {
  id: string;
  fields: {
    id: string;
    type: string;
    label: string;
    value: any;
    showIf?: string;
    instantFeedback?: boolean;
    ifIncorrect?: string;
    correctValue?: string;
  }[];
  date?: string;
};

const useSimpleForm = ({ id, fields, date }: Props) => {
  const formik = usePreservedFormik({
    id,
    initialValues: Object.fromEntries(
      fields.map((field) => [field.id, field.value])
    ),
    date,
  });

  const [showFeedback, setShowFeedback] = React.useState(false);

  const components = (
    <>
      {fields.map(
        ({
          id,
          type,
          label,
          value,
          showIf,
          instantFeedback,
          ifIncorrect,
          correctValue,
        }) => {
          if (showIf != null) {
            if (
              formik.values[showIf] == null ||
              formik.values[showIf] !== "yes"
            )
              return null;
          }
          switch (type) {
            case "subheader":
              return <Text mb={5}>{label}</Text>;
            case "text":
              return (
                <FormControl mb={5}>
                  <FormLabel htmlFor={id}>{label}</FormLabel>
                  <Input
                    id={id}
                    name={id}
                    type={type}
                    value={formik.values[id]}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              );
            case "textarea":
              return (
                <FormControl mb={5}>
                  <FormLabel htmlFor={id}>{label}</FormLabel>
                  <Textarea
                    id={id}
                    name={id}
                    value={formik.values[id]}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              );
            case "boolean":
              return (
                <FormControl mb={5}>
                  <FormLabel htmlFor={id}>{label}</FormLabel>
                  <RadioGroup
                    id={id}
                    name={id}
                    onChange={(val) => {
                      formik.setFieldValue(id, val);
                    }}
                    value={formik.values[id]}
                  >
                    <HStack>
                      <Radio value={"yes"}>Yes</Radio>
                      <Radio value={"no"}>No</Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              );
            case "true-false":
              let feedback = null;
              if (instantFeedback && showFeedback) {
                if (formik.values[id] === correctValue) {
                  feedback = (
                    <Alert status="success" my={3}>
                      <AlertIcon />
                      That's correct!
                    </Alert>
                  );
                } else {
                  feedback = (
                    <Alert status="error" my={3}>
                      <AlertIcon />
                      That's incorrect {ifIncorrect ? "- " + ifIncorrect : null}
                    </Alert>
                  );
                }
              }
              return (
                <FormControl mb={5}>
                  <FormLabel htmlFor={id}>{label}</FormLabel>
                  <RadioGroup
                    id={id}
                    name={id}
                    onChange={(val) => {
                      formik.setFieldValue(id, val);
                    }}
                    value={formik.values[id]}
                  >
                    <HStack>
                      <Radio value={"true"}>True</Radio>
                      <Radio value={"false"}>False</Radio>
                    </HStack>
                  </RadioGroup>
                  {feedback}
                </FormControl>
              );
            case "slider":
              return (
                <FormControl mb={16}>
                  <FormLabel htmlFor={id}>{label}</FormLabel>
                  <Center my={5}>
                    <Slider
                      maxW={400}
                      w={"50%"}
                      min={0}
                      max={3}
                      value={formik.values[id]}
                      onChange={(val) => {
                        formik.setFieldValue(id, val);
                      }}
                    >
                      <SliderMark value={0} mt="2" ml="-2.5" fontSize="sm">
                        Not much
                      </SliderMark>
                      <SliderMark value={3} mt="2" ml="-2.5" fontSize="sm">
                        Very much
                      </SliderMark>
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Center>
                </FormControl>
              );
          }
        }
      )}
      <SaveAlert formik={formik} />
    </>
  );

  return { components, formik, setShowFeedback };
};

export default useSimpleForm;
