import { Button, Text } from "@chakra-ui/react";
import React from "react";
import useGridForm from "../../hooks/useGridForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleFourReflection = ({ date }: Props) => {
  const { components: components1, formik: f1 } = useGridForm({
    id: "m7evaluation",
    count: 7,
    fields: [
      {
        id: "doLow",
        type: "text",
        label: "Helpful to do when feeling low",
        value: "",
      },
      {
        id: "stopLow",
        type: "text",
        label: "Helpful to stop doing when feeling low?",
        value: "",
      },
    ],
    date,
  });

  const { components: components2, formik: f2 } = useGridForm({
    id: "m7evaluation2",
    count: 7,
    fields: [
      {
        id: "doElated",
        type: "text",
        label: "Helpful to do when feeling elated",
        value: "",
      },
      {
        id: "stopElated",
        type: "text",
        label: "Helpful to stop doing when feeling elated",
        value: "",
      },
    ],
    date,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        f1.handleSubmit();
        f2.handleSubmit();
      }}
    >
      <Text my={5}>
        What do you find it helpful to do when you start feeling low? What do
        you find it helpful to stop doing when you start feeling low?
      </Text>
      {components1}
      <Text my={5}>
        What do you find it helpful to do when you start feeling elated? What do
        you find it helpful to stop doing when you start feeling elated?
      </Text>
      {components2}
      {!date || date === "0" ? (
        <SaveButton mt={5} isLoading={f2.isSubmitting || f1.isSubmitting} />
      ) : null}
    </form>
  );
};

export default ModuleFourReflection;
