import {
  Avatar,
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function TeamBio() {
  return (
    <SimpleGrid columns={[1]} spacing={5}>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio</Text>
          </Box>
        </Stack>
      </Box>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio</Text>
          </Box>
        </Stack>
      </Box>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio</Text>
          </Box>
        </Stack>
      </Box>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio</Text>
          </Box>
        </Stack>
      </Box>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio</Text>
          </Box>
        </Stack>
      </Box>
      <Box w={"full"} p={2}>
        <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
          <Avatar
            size={"xl"}
            alt={"Avatar Alt"}
            mb={4}
            mr={4}
            pos={"relative"}
          />
          <Box>
            <Heading fontSize={"2xl"} fontFamily={"body"}>
              A Person
            </Heading>
            <Text>This is my bio. </Text>
          </Box>
        </Stack>
      </Box>
    </SimpleGrid>
  );
}
