import { Analytics, Auth } from "aws-amplify";
import { BASE_API_URL } from "./util";

export type UserInteractionEvent = {
  action: string;
  details: string;
};

export const trackEvent = async (event: UserInteractionEvent) => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/track`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        action: event.action,
        location: window.location.pathname + window.location.search,
        details: event.details,
      }),
    });
    if (!response.ok) {
      throw new Error(`Failed to track event ${JSON.stringify(event)}`);
    }
  } catch (e) {
    console.log(e);
  }
};

export const startSessionTracking = async (): Promise<string | undefined> => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/page-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        location: window.location.pathname + window.location.search,
      }),
    });
    if (!response.ok) {
      throw new Error(`Failed to initiate session`);
    } else {
      const data: { sessionId: string } = await response.json();
      return data.sessionId;
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateSession = async (sessionId: string, duration: number) => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/page-session/${sessionId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        duration,
      }),
    });
    if (!response.ok) {
      throw new Error(`Failed to update session`);
    }
  } catch (e) {
    console.log(e);
  }
};
