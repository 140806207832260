import React from "react";
import { usePreservedFormik } from "./usePreservedFormik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import SaveAlert from "../components/SaveAlert";

type Props = {
  id: string;
  fields: {
    id: string;
    label: string;
    description: string;
    sectionA: {
      label: string;
      value: number;
      stable: boolean;
    }[];
    sectionB: {
      label: string;
      value: number;
      positive: boolean;
    }[];
  }[];
  date?: string;
};

const usePaceQuestionnaire = ({ id, fields, date }: Props) => {
  const formik = usePreservedFormik({
    id,
    initialValues: {},
    date,
  });

  const summaryDisclosure = useDisclosure();

  const allCompleted = fields.every(
    (field) =>
      formik.values[`${field.id}-a`] != null &&
      formik.values[`${field.id}-b`] != null
  );

  const components = (
    <>
      {fields.map(({ id, label, description, sectionA, sectionB }) => (
        <Box mb={5}>
          <Heading fontSize="lg">{label}</Heading>
          <Text mb={2}>{description}</Text>
          <FormControl>
            <FormLabel>
              Which of these possible causes is the one you'd be most likely to
              have chosen? Which is least likely?
            </FormLabel>
            <Flex flexDir="column">
              {sectionA.map(({ label, value, stable }) => (
                <Box
                  onClick={() => {
                    formik.setFieldValue(`${id}-a`, value);
                  }}
                >
                  <Radio
                    value={value}
                    isChecked={formik.values[`${id}-a`] === value}
                  >
                    {label}
                  </Radio>
                </Box>
              ))}
            </Flex>
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>
              Please choose what the likely consequences of this happening are:
            </FormLabel>
            {sectionB.map(({ label, value, positive }) => (
              <Box
                onClick={() => {
                  formik.setFieldValue(`${id}-b`, value);
                }}
              >
                <Radio
                  value={value}
                  isChecked={formik.values[`${id}-b`] === value}
                >
                  {label}
                </Radio>
              </Box>
            ))}
          </FormControl>
        </Box>
      ))}
    </>
  );

  // --- calculate scores
  let stabilityScore = 0;
  let positivityScore = 0;
  fields.forEach((field) => {
    const secAAns = formik.values[`${field.id}-a`];
    const secBAns = formik.values[`${field.id}-b`];
    const secA = field.sectionA.find((a) => a.value === secAAns);
    const secB = field.sectionB.find((b) => b.value === secBAns);
    if (secA && secA.stable) stabilityScore++;
    if (secB && secB.positive) positivityScore++;
  });
  stabilityScore = stabilityScore / fields.length;
  positivityScore = positivityScore / fields.length;
  // ---

  const GetSummaryButton = (
    <>
      <Button
        isDisabled={!allCompleted}
        colorScheme="blue"
        onClick={summaryDisclosure.onOpen}
      >
        Get your personalised summary
      </Button>
      <Modal
        isOpen={summaryDisclosure.isOpen}
        onClose={summaryDisclosure.onClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Personalised Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={3}>
            <Text mb={2}>
              This exercise has been designed to help you think about how you
              typically tend to explain challenges in your child’s and your
              life.
            </Text>
            {stabilityScore < 0.5 ? (
              <Text mb={2}>
                You more frequently chose a scenario where the cause of the
                challenge is something that can be resolved. This is a positive
                way to approve problems, and will help both you and your child
                overcome difficulties.
              </Text>
            ) : (
              <Text mb={2}>
                You more frequently chose a scenario where the cause of the
                challenge was either a problem with your child, or a permanent
                problem that can’t be solved. You may find it helpful to try
                focusing on seeing these situations as raising shorter term
                challenges and exploring potential solutions, rather whose fault
                it was or how difficult it will be to fix. This can help you and
                your child to overcome difficulties and reduce your stress
                levels.
              </Text>
            )}
            {positivityScore < 0.5 ? (
              <Text>
                You more frequently selected the negative outcome of a scenario,
                instead of the positive. You may find it useful to consider
                alternative consequences to actions, to open you mind to the
                idea that the worst may not always happen. This can help you to
                avoid stress and fear.
              </Text>
            ) : (
              <Text>
                You more frequently selected the positive outcome of a scenario,
                instead of the negative. This is a good approach to have, but
                being aware of all potential outcomes – good or bad – is
                recommended, to help you plan for all eventualities.
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  return { components, formik, allCompleted, GetSummaryButton };
};

export default usePaceQuestionnaire;
