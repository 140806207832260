import {
  trackEvent,
  startSessionTracking,
  updateSession,
} from "./analyticsApi";

export const trackPageViews = async () => {
  await trackEvent({
    action: "pageView",
    details: `Navigated to ${location.pathname + location.search}`,
  });
};

export const trackSessionLength = async () => {
  const timerInterval = 3000;
  sessionStorage.setItem("session-length", "0");
  const sessionId = await startSessionTracking();
  let interval: NodeJS.Timer | null;

  const startInterval = (): NodeJS.Timer => {
    return setInterval(async () => {
      const sessionLength = parseInt(
        sessionStorage.getItem("session-length") || "0"
      );
      const duration = sessionLength + timerInterval / 1000;
      sessionStorage.setItem("session-length", duration.toString());
      if (sessionId) await updateSession(sessionId, duration);
    }, timerInterval);
  };

  const checkVisibility = () => {
    if (document.visibilityState === "visible" && interval === null) {
      interval = startInterval();
    } else if (interval !== null) {
      clearInterval(interval);
      interval = null;
    }
  };

  interval = startInterval();
  document.addEventListener("visibilitychange", checkVisibility);

  return () => {
    document.removeEventListener("visibilitychange", checkVisibility);
    if (interval) {
      clearInterval(interval);
    }
  };
};

export const trackInputFocusEvents = async () => {
  document.querySelectorAll("input").forEach((input) =>
    input.addEventListener("focus", async () => {
      const label = document.querySelector<HTMLLabelElement>(
        `label[for="${input.id}"]`
      );
      if (label) {
        await trackEvent({
          action: "focus",
          details: `Focused on input labelled '${label.innerText}'`,
        });
      }
    })
  );
};

export const trackVideoEmbeds = async () => {
  window.addEventListener("blur", async (e) => {
    if (document.activeElement instanceof HTMLIFrameElement) {
      if (document.activeElement.src.startsWith("https://player.vimeo.com")) {
        await trackEvent({
          action: "videoPlayed",
          details: `started playback of video '${document.activeElement.title}' (${document.activeElement.src})`,
        });
      }
    }
  });
};

export const trackClicks = async () => {
  function findParentByTagName(element: any, tagName: string) {
    let parent = element;
    while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
      parent = parent.parentNode;
    }
    return parent;
  }

  document.addEventListener("click", async (e) => {
    const button =
      e.target instanceof HTMLButtonElement
        ? e.target
        : findParentByTagName(e.target, "A")
        ? findParentByTagName(e.target, "A")
        : null;
    if (button) {
      await trackEvent({
        action: "click",
        details: `Clicked button with label '${
          button.innerText
            ? button.innerText
            : button.attributes["aria-label"]?.value
        }'`,
      });
    }
  });
};
