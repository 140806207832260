import {
  Button,
  FormControl,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { usePreservedFormik } from "./usePreservedFormik";
import { PlusSquareIcon } from "@chakra-ui/icons";
import SaveAlert from "../components/SaveAlert";

type Props = {
  id: string;
  count?: number;
  fields: {
    id: string;
    type: string;
    label: string;
    value: any;
  }[];
  expandable?: boolean;
  date?: string;
};

const useGridForm = ({ id, fields, count, expandable, date }: Props) => {
  const formik = usePreservedFormik({
    id,
    initialValues: {},
    date,
  });

  const [rowCount, setRowCount] = useState(5);
  useEffect(() => {
    if (count) setRowCount(count);
  }, []);

  useEffect(() => {
    if (expandable) {
      let greatest = 0;
      const vals = Object.values(formik.values);
      Object.keys(formik.values).forEach((key: string, i) => {
        if (parseInt(key.split("-")[1]) > greatest && vals[i] !== "") {
          greatest = parseInt(key.split("-")[1]);
        }
      });
      if (greatest > rowCount - 2) setRowCount(greatest + 1);
    }
  }, [formik.values]);

  const components = (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {fields.map(({ label }) => (
                <Th>{label}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {[...new Array(rowCount)].map((_, index) => (
              <Tr key={index}>
                {fields
                  .map(({ id, type }) => {
                    const formid = `${id}-${index}`;

                    switch (type) {
                      case "text":
                        return (
                          <FormControl>
                            <Input
                              id={formid}
                              name={formid}
                              type={type}
                              value={formik.values[formid]}
                              onChange={formik.handleChange}
                            />
                          </FormControl>
                        );
                      case "textarea":
                        return (
                          <FormControl>
                            <Textarea
                              id={formid}
                              name={formid}
                              value={formik.values[formid]}
                              onChange={formik.handleChange}
                            />
                          </FormControl>
                        );
                      case "boolean":
                        return (
                          <FormControl>
                            <RadioGroup
                              id={formid}
                              name={formid}
                              onChange={(val) => {
                                formik.setFieldValue(formid, val);
                              }}
                              value={formik.values[formid]}
                            >
                              <HStack>
                                <Radio value={"yes"}>Yes</Radio>
                                <Radio value={"no"}>No</Radio>
                              </HStack>
                            </RadioGroup>
                          </FormControl>
                        );
                    }
                  })
                  .map((c) => (
                    <Td>{c}</Td>
                  ))}
              </Tr>
            ))}
            {expandable ? (
              <Tr>
                <Td colSpan={fields.length}>
                  <Button
                    w="full"
                    leftIcon={<PlusSquareIcon />}
                    onClick={() => {
                      setRowCount(rowCount + 1);
                    }}
                  >
                    Add Row
                  </Button>
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>{" "}
      <SaveAlert formik={formik} />
    </>
  );

  return { components, formik };
};

export default useGridForm;
