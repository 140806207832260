import React from "react";
import useSimpleForm from "../../hooks/useSimpleForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleEightReflection = ({ date }: Props) => {
  const { components, formik } = useSimpleForm({
    id: "module-8-reflection",
    fields: [
      {
        id: "question1",
        label: "Do you have any sleep problems?",
        type: "boolean",
        value: null,
      },
      {
        id: "subquestion11",
        label: "What sleep problem(s) do you have?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "subquestion12",
        label: "What helps you with your sleep?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "subquestion13",
        label: "What makes it worse?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "subquestion14",
        label:
          "What will you do differently following this module to improve your sleep?",
        type: "textarea",
        value: "",
        showIf: "question1",
      },
      {
        id: "question2",
        label: "Does your child have any sleep problems?",
        type: "boolean",
        value: null,
      },
      {
        id: "subquestion21",
        label: "What sleep problem(s) does your child have?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
      {
        id: "subquestion22",
        label: "What helps your child with their sleep?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
      {
        id: "subquestion23",
        label: "What makes it worse?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
      {
        id: "subquestion24",
        label:
          "What will you do differently following this module to improve your child's sleep?",
        type: "textarea",
        value: "",
        showIf: "question2",
      },
    ],
    date,
  });

  return (
    <form
      style={{ marginBottom: "1em" }}
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {components}
      {!date || date === "0" ? (
        <SaveButton isLoading={formik.isSubmitting} />
      ) : null}
    </form>
  );
};

export default ModuleEightReflection;
