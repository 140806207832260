import React, { useCallback, useMemo, useState } from "react";

export type QueryCacheContextType = {
  dirty: boolean;
  setDirty: (flag: boolean) => void;
};

export const FormContext = React.createContext<QueryCacheContextType>({
  dirty: false,
  setDirty: () => {},
});

const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const [dirty, setDirty] = useState<boolean>(false);

  return (
    <FormContext.Provider value={{ dirty, setDirty }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
