import { Amplify } from "aws-amplify";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import {
  trackClicks,
  trackInputFocusEvents,
  trackPageViews,
  trackSessionLength,
  trackVideoEmbeds,
} from "./src/data/clientsideTrackers";

import React from "react"

const PRODUCTION =
  typeof window !== "undefined"
    ? window.location.hostname.endsWith("ibpi.co.uk")
    : false;

const productionValues = {
  userPoolId: "eu-west-2_8eEFoCX2D",
  userPoolWebClientId: "7lrsh855af8qg7dj5uvgbgpv41",
};

const stagingValues = {
  userPoolId: "eu-west-2_djy6HJl4W",
  userPoolWebClientId: "41dugfn2hq3cjsssp15eol48b7",
};

Amplify.configure({
  Auth: {
    region: "eu-west-2",
    ...(PRODUCTION ? productionValues : stagingValues),
    authenticationFlowType: "USER_SRP_AUTH",
    mandatorySignIn: false,
  },
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

trackClicks();
trackVideoEmbeds();

let cleanup: (() => void) | null = null;

export const onRouteUpdate = async ({ location, prevLocation }) => {
  trackPageViews();
  trackInputFocusEvents();
  if (cleanup !== null) cleanup();
  cleanup = await trackSessionLength();
  sessionStorage.clear();
};

export const onRenderBody = ({ setHtmlAttributes }) => {
  setHtmlAttributes({
    lang: "en",
  })
}