import { Text } from "@chakra-ui/react";
import React from "react";
import useGridForm from "../../hooks/useGridForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleTwoSelfEvaluation = ({ date }: Props) => {
  const { components: grid1, formik: formik1 } = useGridForm({
    id: "m2evaluation1",
    fields: [
      {
        id: "parentingPoint",
        type: "text",
        label: "What is good about my parenting?",
        value: "",
      },
      {
        id: "bipolarRelated",
        type: "boolean",
        label: "Bipolar related?",
        value: "",
      },
    ],
    count: 4,
    expandable: true,
    date,
  });

  const { components: grid2, formik: formik2 } = useGridForm({
    id: "m2evaluation2",
    fields: [
      {
        id: "parentingChallenges",
        type: "text",
        label: "Parenting challenges",
        value: "",
      },
      {
        id: "bipolarRelated",
        type: "boolean",
        label: "Bipolar related?",
        value: "",
      },
    ],
    count: 4,
    expandable: true,
    date,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik1.handleSubmit();
        formik2.handleSubmit();
      }}
    >
      <Text mb={10}>
        Think about what is good about how you parent your children. Try to come
        up with at least four things – for each one please note it down in the
        free text box in the left hand column and record whether or not you
        think it is related to your experience of bipolar disorder by clicking
        yes or no in the right hand column.
      </Text>

      {grid1}

      <Text my={10}>
        Now think about any challenges with how you parent your children. Try to
        come up with at least four things – for each one please note it down in
        the free text box in the left hand column and record whether or not you
        think it is related to your experience of bipolar disorder by clicking
        yes or no in the right hand column.
      </Text>

      {grid2}

      {!date || date === "0" ? (
        <SaveButton
          mt={5}
          isLoading={formik1.isSubmitting || formik2.isSubmitting}
        />
      ) : null}
    </form>
  );
};

export default ModuleTwoSelfEvaluation;
