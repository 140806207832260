import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins";

const theme = {
  fonts: {
    heading: `'poppins'`,
    body: `'poppins'`,
  },
  colors: {
    green: {
      100: "#f0ff9e",
      200: "#d6ea84",
      300: "#bdd16b",
      400: "#a3b751",
      500: "#8a9e38",
      600: "#70841e",
      700: "#576b05",
      800: "#3d5100",
      900: "#243800",
    },
    blue: {
      100: "#ededff",
      200: "#d3d3f5",
      300: "#babadc",
      400: "#a0a0c2",
      500: "#8787a9",
      600: "#6d6d8f",
      700: "#545476",
      800: "#3a3a5c",
      900: "#212143",
    },
    state: {
      100: "#92de59",
      200: "#f0948a",
    },
  },
};

export default extendTheme(theme);
