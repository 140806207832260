import useRuminationAndDampeningForm from "../../hooks/useRuminationAndDampeningForm";
import { Button, Flex, Spacer, Text, Box } from "@chakra-ui/react";
import React from "react";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleThreeReflection = ({ date }: Props) => {
  const { components, formik, allCompleted, GetSummaryButton } =
    useRuminationAndDampeningForm({
      id: "module-3-reflection",
      fields: [
        {
          id: "1",
          label: "notice how you feel full of energy",
          subGroup: "emotion",
        },
        { id: "2", label: "savour this moment", subGroup: "emotion" },
        {
          id: "3",
          label: `think "I am getting everything done"`,
          subGroup: "self",
        },
        {
          id: "4",
          label: `think about how you feel up for doing everything`,
          subGroup: "emotion",
        },
        {
          id: "5",
          label: `think "I am living up to my potential"`,
          subGroup: "self",
        },
        {
          id: "6",
          label: `think "It is too good to  be true"`,
          subGroup: "dampening",
        },
        {
          id: "7",
          label: `think about how happy you feel`,
          subGroup: "emotion",
        },
        {
          id: "8",
          label: `think about how strong you feel`,
          subGroup: "emotion",
        },
        {
          id: "9",
          label: `think about things that could go wrong`,
          subGroup: "dampening",
        },
        {
          id: "10",
          label: "remind yourself that these feelings won't last",
          subGroup: "dampening",
        },
        {
          id: "11",
          label: `think "people will think I am bragging"`,
          subGroup: "dampening",
        },
        {
          id: "12",
          label: `think about how hard it is to concentrate`,
          subGroup: "dampening",
        },
        {
          id: "13",
          label: `think "I am achieving everything"`,
          subGroup: "self",
        },
        {
          id: "14",
          label: `think "I don't deserve this"`,
          subGroup: "dampening",
        },
        {
          id: "15",
          label: `think "my streak of luck is going to end soon"`,
          subGroup: "dampening",
        },
        {
          id: "16",
          label: `think about how proud you are of yourself`,
          subGroup: "self",
        },
        {
          id: "17",
          label: `think about the things that have not gone well for you`,
          subGroup: "dampening",
        },
      ],
      header: "When your mood feels positive, how much do you:",
      date,
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text>
        People think and do many different things when they feel happy. Please
        read each of the following items and indicate whether you never,
        sometimes, often, or always think or do each one when you feel happy,
        excited, or enthused. Please indicate what you generally do, not what
        you think you should do.
      </Text>
      
      {components}
      {!date || date === "0" ? (
        <Flex mt={3} flexDirection={{ base:"column", md:"row"}}>
          <SaveButton
            isLoading={formik.isSubmitting}
            isDisabled={!allCompleted}
            my={3}
          />
          <Box mx={2} />
          {GetSummaryButton}
        </Flex>
      ) : null}
      <Text fontSize="0.6rem" mt={5}>
        Feldman, G. C., Joormann, J., & Johnson, S. L. (2008). Responses to
        Positive Affect: A Self-Report Measure of Rumination and Dampening.
        Cognitive therapy and research, 32(4), 507–525.
        https://doi.org/10.1007/s10608-006-9083-0.
      </Text>
    </form>
  );
};

export default ModuleThreeReflection;
