import { BASE_API_URL, useMutation, useQuery } from "./util";
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";

export const useRehydratedForm = (formId?: string, disabled?: boolean) => {
  const [state, setState] = useState<any>({
    data: [],
    loading: true,
  });

  const fetchData = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(
      `${BASE_API_URL}/form-response/${formId}/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responses = await response.json();
    const data = responses.Items.sort(
      (a: any, b: any) => b.timestamp - a.timestamp
    );

    setState({
      data: data,
      loading: false,
    });
  }, []);

  return { ...state, fetchData };
};

export const useAllReflections = () => {
  const [state, setState] = useState<any>({
    data: [],
    loading: true,
  });

  const fetchData = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/form-response/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const responses = await response.json();
    const data = responses.Items.sort(
      (a: any, b: any) => b.timestamp - a.timestamp
    );

    setState({
      data: data ?? {},
      loading: false,
    });
  }, []);

  return { ...state, fetchData };
};

export const useHydrateForm = (formId: string) => {
  return useMutation(async (formResponse: any) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/form-response`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formId,
        response: formResponse,
      }),
    });
    return response.ok;
  });
};
