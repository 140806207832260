import { Button } from "@chakra-ui/react";
import React from "react";
import useGridForm from "../../hooks/useGridForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleSixSelfEvaluation = ({ date }: Props) => {
  const { components, formik } = useGridForm({
    id: "m6evaluation2",
    count: 4,
    expandable: true,
    fields: [
      {
        id: "helpfulAdvice",
        type: "text",
        label: "Less helpful person for advice and support",
        value: "",
      },
      {
        id: "seen",
        type: "text",
        label: "How many times seen per month?",
        value: "",
      },
      {
        id: "options",
        type: "text",
        label: "Options for less contact if needed?",
        value: "",
      },
    ],
    date,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {components}

      {!date || date === "0" ? (
        <SaveButton mt={5} isLoading={formik.isSubmitting} />
      ) : null}
    </form>
  );
};

export default ModuleSixSelfEvaluation;
