import { useEffect, useState } from "react";
import removeMd from "remove-markdown";

const PRODUCTION =
  typeof window !== "undefined"
    ? window.location.hostname.endsWith("ibpi.co.uk")
    : false;

export const BASE_API_URL = PRODUCTION
  ? "https://api.ibpi.co.uk"
  : "https://w7cdh9ydca.execute-api.eu-west-2.amazonaws.com/stg";

export const getCacheFunctions = () => {
  // session storage cache functions
  const getCache = (key: string) => {
    const cache = sessionStorage.getItem(key);
    if (cache) {
      return JSON.parse(cache);
    }
    return null;
  };

  const setCache = (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  return { getCache, setCache };
};

export const useQuery = <T = any>(
  queryKey: string | undefined,
  func: () => Promise<T>,
  options?: { enabled?: boolean }
) => {
  const { getCache, setCache } = getCacheFunctions();
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();

  const refetch = async () => {
    const data = await fetch();
    if (data) {
      setData(data);
      setIsLoading(false);
      setIsSuccess(true);
    } else {
      setIsError(true);
    }
  };

  const fetch = () => {
    if (!options || options?.enabled) {
      setIsLoading(true);

      return func()
        .then((data: T) => {
          return data;
        })
        .catch((e: any) => null);
    }
  };

  // I've commented this out, as it looks like it's causing over fetching
  useEffect(() => {
    console.count("useQuery useEffect");
    (async () => {
      const data = await fetch();
      if (data) {
        setData(data);
        setIsLoading(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
      }
    })();
  }, [queryKey, options?.enabled]);

  return { data, isLoading, isSuccess, isError, refetch };
};

export const useMutation = (
  func: Function,
  options?: { onSuccess: () => void }
) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();
  const [error, setError] = useState<any>();

  const mutateAsync = async (...args: any[]) => {
    setIsLoading(true);
    try {
      //@ts-ignore
      const result = await func(...args);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsError(true);
      setError(e);
      setIsLoading(false);
      return null;
    }
  };

  return { mutateAsync, isLoading, isError, error };
};

export const cleanRawBody = (
  rawBody: string,
  replaceNewLines: boolean = false,
  replaceIBPI: boolean = false,
  replaceNewLineWithBreak: boolean = false
) => {
  let clean = rawBody.trim();
  // remove metadata
  if (rawBody.includes("---")) {
    let secondLine = rawBody.indexOf("---", rawBody.indexOf("---") + 1);
    clean = clean.substring(secondLine + 3).trim();
  }
  // remove any html
  clean = clean.replace(/<\/?[^>]+(>|$)/g, "");
  // remove any markdown
  clean = removeMd(clean);
  // remove anything beginning with import
  clean = clean.replace(/import.*;/g, "");
  // replace newlines with spaces
  if (replaceNewLines) clean = clean.replace(/\n/g, " ");
  // replace newlines with breaks
  if (replaceNewLineWithBreak) clean = clean.replace(/\n/g, "<br />");
  // replace IBPI with I.B.P.I for tts
  if (replaceIBPI) clean = clean.replace(/IBPI/g, "I.B.P.I");
  return clean;
};
