import { usePreservedFormik } from "./usePreservedFormik";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Flex,
  Box,
  Divider,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import SaveAlert from "../components/SaveAlert";

type Props = {
  id: string;
  fields: {
    id: string;
    label: string;
    subGroup: "emotion" | "self" | "dampening";
    value?: number;
  }[];
  header: string;
  date?: string;
};

const useRuminationAndDampeningForm = ({ id, fields, header, date }: Props) => {
  const formik = usePreservedFormik({
    id,
    initialValues: Object.fromEntries(
      fields.map((field) => [field.id, field.value])
    ),
    date,
  });

  const allCompleted = fields.every((field) => formik.values[field.id] != null);

  const emotionRuminationScore = fields
    .filter((field) => field.subGroup === "emotion")
    .reduce((acc, field) => acc + formik.values[field.id], 0);
  const selfRuminationScore = fields
    .filter((field) => field.subGroup === "self")
    .reduce((acc, field) => acc + formik.values[field.id], 0);
  const dampeningScore = fields
    .filter((field) => field.subGroup === "dampening")
    .reduce((acc, field) => acc + formik.values[field.id], 0);

  const summaryDisclosure = useDisclosure();

  const components = (
    <>
      <Flex direction="column" mt={2} gap={3} mb={10}>
        <Text fontWeight="bold" fontSize="sm" gap={3}>
          {header.toUpperCase()}
        </Text>
        {fields.map(({ id, label, subGroup }) => (
          <Flex key={id} direction="column" gap={3} pt={3} maxW={600}>
            <Divider />
            <Text fontSize="md" fontWeight={500} mt={3}>
              {label}
            </Text>
            <Flex
              direction={{ base: "column", md: "row" }}
              gap={3}
              justifyContent={"space-between"}
            >
              {["Almost Never", "Sometimes", "Often", "Almost Always"].map(
                (text, index) => (
                  <Box
                    textAlign="left"
                    onClick={() => {
                      formik.setFieldValue(id, index + 1);
                    }}
                  >
                    <Radio
                      value={index + 1}
                      isChecked={formik.values[id] === index + 1}
                    >
                      {text}
                    </Radio>
                  </Box>
                )
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <SaveAlert formik={formik} />
    </>
  );

  const summaryText = useMemo(() => {
    const highest = Math.max(
      emotionRuminationScore,
      selfRuminationScore,
      dampeningScore
    );
    if (
      highest == emotionRuminationScore &&
      highest != selfRuminationScore &&
      highest != dampeningScore
    ) {
      return (
        <Text>
          The way you answered the questions shows that when you experience
          positive emotions, you tend to think a lot about your mood and energy
          levels. For example, you might really notice how happy, elated or
          wonderful you feel, and then think about this in detail or savour the
          moment. Although this can improve low mood and help you to feel good
          about yourself, if you take it too far then it could make it more
          likely that you will experience a high.
        </Text>
      );
    } else if (
      highest == selfRuminationScore &&
      highest != emotionRuminationScore &&
      highest != dampeningScore
    ) {
      return (
        <Text>
          The way you answered the questions shows that when you experience
          positive emotions, you tend to think a lot about how positive you feel
          about yourself and your achievements. For example, you might notice
          how much progress you are making and how proud you are about this.
          Although this can improve low mood and help you to feel good about
          yourself, if you take it too far then it could make it more likely
          that you will experience a high.
        </Text>
      );
    } else if (
      highest == dampeningScore &&
      highest != emotionRuminationScore &&
      highest != selfRuminationScore
    ) {
      return (
        <Text>
          The way we respond to positive events and changes in mood in the
          present can affect mood in the future. The way you answered the
          questions shows that when you experience positive emotions, you tend
          to play things down. For example, you might tell yourself that you
          don’t deserve to feel this good or it won’t last. Maybe you find other
          ways to talk yourself out of feeling good. Although this approach can
          help you to feel calm, if you take it too far then it could lead to
          you feeling low in mood and less good about yourself.
        </Text>
      );
    } else {
      return (
        <Text>
          The general way that you answered your questions shows that when you
          experience positive emotions, you don’t tend to rely on a particular
          strategy most of the time (like playing down positives or focusing on
          how the positive emotion feels or how much energy you have). Having a
          range of ways of responding to changes in mood can be useful in
          maintaining a balanced mood.
        </Text>
      );
    }
  }, [emotionRuminationScore, selfRuminationScore, dampeningScore]);

  const GetSummaryButton = (
    <>
      <Button
        my={3}
        isDisabled={!allCompleted}
        colorScheme="blue"
        onClick={summaryDisclosure.onOpen}
      >
        Get your personalised summary
      </Button>
      <Modal
        isOpen={summaryDisclosure.isOpen}
        onClose={summaryDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Personalised Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={3}>
            <Text>
              The way we respond to positive events and changes in mood in the
              present can affect mood in the future.
            </Text>
            <br />
            {summaryText}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  return {
    components,
    formik,
    allCompleted,
    GetSummaryButton,
  };
};

export default useRuminationAndDampeningForm;
