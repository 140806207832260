import React from "react";
import useSimpleForm from "../../hooks/useSimpleForm";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleOneReflection = ({ date }: Props) => {
  const { components, formik, setShowFeedback } = useSimpleForm({
    id: "module-1-reflection",
    fields: [
      {
        id: "challenges",
        label:
          "What challenges has your experience of bipolar presented you with?",
        type: "textarea",
        value: "",
      },
      {
        id: "benefits",
        label:
          "Do you think bipolar has had any benefits for you? If so what have they been?",
        type: "textarea",
        value: "",
      },
      {
        id: "subheader2",
        label:
          "Here are some statements. Please read each one and then give your answer by clicking on whether you think it is true or false. You can come back and review these answers again whenever you want.  We recommend you return to this as you progress through the website, to see if you notice any changes. When you have given your answers, you will be able to see the answers given by the IBPI team.",
        type: "subheader",
        value: "",
      },
      {
        id: "multi1",
        label:
          "Bipolar disorder always means having frequent episodes of mania and depression",
        type: "true-false",
        value: "",
        instantFeedback: true,
        correctValue: "false",
        ifIncorrect:
          "Although many people think this, the number of episodes of mania or depression experienced is actually unique to each person and can be influenced by different factors.",
      },
      {
        id: "multi2",
        label:
          "It is not possible to be successful in life if you have bipolar disorder.",
        type: "true-false",
        value: "",
        instantFeedback: true,
        correctValue: "false",
        ifIncorrect:
          "Whilst bipolar can present challenges that other people may not face, lots people who experience bipolar experience successes in many areas of their lives, and enjoy happy, fulfilling roles within their families.",
      },
      {
        id: "multi3",
        label:
          "Bipolar disorder is often associated with fluctuations in mood and sleep.",
        type: "true-false",
        value: "",
        instantFeedback: true,
        correctValue: "true",
        ifIncorrect:
          "People with bipolar often experience mood fluctuations and changes in sleeping patterns or quality of sleep, although how often and how severe these sleep problems are will differ from person to person.",
      },
      {
        id: "multi4",
        label: "Bipolar disorder can present challenges for parenting.",
        type: "true-false",
        value: "",
        instantFeedback: true,
        correctValue: "true",
        ifIncorrect:
          "Parenting is difficult for all of us! No one is perfect and we all experience different challenges with our children. However, some aspects may be more challenging for those also living with bipolar.",
      },
    ],
    date,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onChange={() => {
        setShowFeedback(false);
      }}
    >
      {components}
      {!date || date === "0" ? (
        <SaveButton
          isLoading={formik.isSubmitting}
          onClickExtra={() => {
            setShowFeedback(true);
          }}
        />
      ) : null}
    </form>
  );
};

export default ModuleOneReflection;
