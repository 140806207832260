import { Button, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import usePaceQuestionnaire from "../../hooks/usePaceQuestionnaire";
import SaveButton from "./SaveButton";

type Props = {
  date?: string;
};

const ModuleFiveReflectionTwo = ({ date }: Props) => {
  const { components, formik, allCompleted, GetSummaryButton } =
    usePaceQuestionnaire({
      id: "module-5-reflection-2",
      fields: [
        {
          id: "scenario1",
          label: "Scenario 1",
          description:
            "My child fails or doesn’t do well on an important task (e.g. for younger children, a key stage task like cutting or art) or project at school.",
          sectionA: [
            {
              label:
                "My child didn't work hard enough on this exam or project or to master these skills",
              value: 1,
              stable: false,
            },
            {
              label:
                "My child isn't smart enough, s/he never does well in anything",
              value: 2,
              stable: true,
            },
            {
              label:
                "It will be better when this class is over because this teacher just isn't any good",
              value: 3,
              stable: true,
            },
            {
              label: "The test or task was too hard",
              value: 4,
              stable: false,
            },
          ],
          sectionB: [
            {
              label:
                "This will motivate my child to study more or try harder so that he/she will do better in the future",
              value: 1,
              positive: true,
            },
            {
              label:
                "Now this is going to be on my child's record and will affect his/her chances later on",
              value: 2,
              positive: false,
            },
          ],
        },
        {
          id: "scenario2",
          label: "Scenario 2",
          description:
            "Everyone in the class is invited to a party but my child.",
          sectionA: [
            {
              label:
                "Of course my child wasn't invited. S/he isn't easy to get along with",
              value: 1,
              stable: true,
            },
            {
              label:
                "All the other students are part of the same clique. People always have a hard time letting new people into their groups",
              value: 2,
              stable: true,
            },
            {
              label:
                "My child was too quiet in that class. S/he didn't give people the chance",
              value: 3,
              stable: false,
            },
            {
              label:
                "The child throwing the party must have messed up and forgotten to send my child's invitation",
              value: 4,
              stable: false,
            },
          ],
          sectionB: [
            {
              label:
                "This is just one event and it doesn't mean that it will happen again",
              value: 1,
              positive: true,
            },
            {
              label:
                "Now my child will be seen as an outcast and people won't invite him/her to other parties either",
              value: 2,
              positive: false,
            },
          ],
        },
        {
          id: "scenario3",
          label: "Scenario 3",
          description:
            "My child gives an oral report (e.g. show and tell for younger children) in class and no one pays attention.",
          sectionA: [
            {
              label:
                "People aren't good at paying attention to what other people have to say",
              value: 1,
              stable: true,
            },
            {
              label:
                "It was just my child's topic. No one could have made it interesting",
              value: 2,
              stable: false,
            },
            {
              label: "My child never has anything interesting to say",
              value: 3,
              stable: true,
            },
            {
              label:
                "If my child had done more work, it would have been more interesting",
              value: 4,
              stable: false,
            },
          ],
          sectionB: [
            {
              label:
                "My child can use this as a learning experience, and present things differently in the future",
              value: 1,
              positive: true,
            },
            {
              label:
                "This experience will make my child never want to give a speech again and so, s/he will never learn to give good presentations",
              value: 2,
              positive: false,
            },
          ],
        },
        {
          id: "scenario4",
          label: "Scenario 4",
          description:
            "My child overhears another child making negative comments about him/her.",
          sectionA: [
            {
              label: "That classmate was in a bad mood that day",
              value: 1,
              stable: false,
            },
            {
              label:
                "My child wasn't nice to this person and sometimes when people aren't nice to others, they say mean things about you",
              value: 2,
              stable: false,
            },
            {
              label: "My child does have lots of faults",
              value: 3,
              stable: true,
            },
            {
              label:
                "My child is always going to be encountering people who have negative things to say about everyone",
              value: 4,
              stable: true,
            },
          ],
          sectionB: [
            {
              label:
                "Once one person starts saying negative things, it snowballs and it just keeps happening",
              value: 1,
              positive: false,
            },
            {
              label:
                "From time to time people may say negative things about my child, but it won't carry into the future",
              value: 2,
              positive: true,
            },
          ],
        },
        {
          id: "scenario5",
          label: "Scenario 5",
          description:
            "My child loses a competition (e.g. for younger children, a colouring-in competition) or an athletic event.",
          sectionA: [
            {
              label: "My child never does what he/she needs to do to win",
              value: 1,
              stable: true,
            },
            {
              label: "With more practice, my child will be able to win",
              value: 2,
              stable: false,
            },
            {
              label: "My child just had some bad luck that day",
              value: 3,
              stable: false,
            },
            {
              label: "Contests are always rigged",
              value: 4,
              stable: true,
            },
          ],
          sectionB: [
            {
              label:
                "Losing this competition means my child will never win anything",
              value: 1,
              positive: false,
            },
            {
              label:
                "It is just one competition that will not impact on future competitions",
              value: 2,
              positive: true,
            },
          ],
        },
        {
          id: "scenario6",
          label: "Scenario 6",
          description:
            "My child asks someone to go to do something and the other child says no (e.g. for younger children, to go on a play date or for older children, to go to a dance or film).",
          sectionA: [
            {
              label:
                "Why would someone want to go to an event with my child? S/he is no fun to be with",
              value: 1,
              stable: true,
            },
            {
              label:
                "The other child probably wanted to go with his or her friends",
              value: 2,
              stable: false,
            },
            {
              label:
                "My child is always going to be meeting people who don't want to commit to dates or events",
              value: 3,
              stable: true,
            },
            {
              label:
                "My child waited too long to ask the other child and s/he already had plans",
              value: 4,
              stable: false,
            },
          ],
          sectionB: [
            {
              label:
                "People will remember this and my child will always have difficulty finding people to do things with in future",
              value: 1,
              positive: false,
            },
            {
              label:
                "In the future the number of acceptances that my child gets will show him/her how insignificant this one incident really is",
              value: 2,
              positive: true,
            },
          ],
        },
      ],
      date,
    });

  return (
    <form
      style={{ marginBottom: "1em" }}
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {components}
      {!date || date === "0" ? (
        <Flex>
          <SaveButton
            isLoading={formik.isSubmitting}
            isDisabled={!allCompleted}
          />
          <Spacer />
          {GetSummaryButton}
        </Flex>
      ) : null}
      <Text fontSize="0.6rem" mt={5}>
        Alloy, L. B., Abramson, L. Y., Hogan, M. E., Whitehouse, W. G., Rose, D.
        T., Robinson, M. S., Kim, R. S., & Lapkin, J. B. (2000). The
        Temple-Wisconsin Cognitive Vulnerability to Depression Project: Lifetime
        history of Axis I psychopathology in individuals at high and low
        cognitive risk for depression. Journal of Abnormal Psychology, 109(3),
        403–418.
      </Text>
    </form>
  );
};

export default ModuleFiveReflectionTwo;
